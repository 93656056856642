<template>
  <div>
    <filters-card
      :data-list="dataList.customers"
      :get-data-list="getDataList"
    />
    <b-card title="İzleme Özeti">
      <summary-card
        v-if="dataList.summary.length > 0"
        :data-list="dataList.summary"
      />
      <b-alert
        v-else
        show
        variant="warning"
      >
        <div class="alert-body text-center">
          Henüz izleme kayıt bulunmamaktadır.
        </div>
      </b-alert>
    </b-card>
    <b-card title="Favoriler">
      <like-card
        v-if="dataList.likes.length > 0"
        :data-list="dataList.likes"
      />
      <b-alert
        v-else
        show
        variant="warning"
      >
        <div class="alert-body text-center">
          Kayıt bulunmamaktadır.
        </div>
      </b-alert>
    </b-card>
    <b-card title="Müşteri İzleme Detayı">
      <details-card :data-list="dataList.details" />
    </b-card>
  </div>
</template>

<script>
import { BCard, BAlert } from 'bootstrap-vue'
import FiltersCard from '@/views/Admin/Catalogs/elements/CatalogView/Tracings/FiltersCard.vue'
import SummaryCard from '@/views/Admin/Catalogs/elements/CatalogView/Tracings/SummaryCard.vue'
import LikeCard from '@/views/Admin/Catalogs/elements/CatalogView/Tracings/LikeCard.vue'
import DetailsCard from '@/views/Admin/Catalogs/elements/CatalogView/Tracings/DetailsCard.vue'

export default {
  name: 'Tracings',
  components: {
    BCard,
    BAlert,
    FiltersCard,
    SummaryCard,
    LikeCard,
    DetailsCard,
  },
  computed: {
    dataList() {
      return this.$store.getters['catalogTracings/dataList']
    },
    filterParams() {
      return this.$store.getters['catalogTracings/filterParams']
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$store.dispatch('catalogTracings/getDataList', {
        id_catalogs: this.$route.params.id,
        id_customers: this.filterParams.id_customers,
      })
    },
  },
}
</script>
