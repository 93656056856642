<template>
  <b-card no-body>
    <b-table
      v-if="dataList.length"
      :fields="fields"
      :items="dataList"
      striped
    >
      <template #cell(title)="data">
        <div class="font-weight-bold text-primary">
          {{ data.item.title }}
        </div>
        <div class="font-small-2">
          {{ data.item.body }}
        </div>
      </template>
      <template #cell(is_pushed)="data">
        <b-badge
          v-if="data.item.is_pushed"
          variant="success"
        >
          Gönderildi
        </b-badge>
        <b-badge
          v-else
          variant="warning"
        >
          Beklemede
        </b-badge>
      </template>
      <template #cell(push_date)="data">
        <div>
          {{ moment(data.item.push_date + ' ' + data.item.push_time).format('LLLL') }}
        </div>
      </template>
      <template #cell(control)="data">
        <div class="text-right">
          <b-button
            variant="flat-danger"
            class="btn-icon"
            pill
            :disabled="data.item.is_pushed"
            @click="removeData(data.item.id)"
          >
            <FeatherIcon icon="TrashIcon" />
          </b-button>
        </div>
      </template>
    </b-table>
    <b-card-body
      v-else
      class="pb-0"
    >
      <b-alert
        show
        variant="info"
      >
        <div class="text-center alert-body">
          Henüz planlanmış bildirim bulunmamaktadır.
        </div>
      </b-alert>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BBadge, BButton, BCardBody, BAlert,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'Index',
  components: {
    BAlert,
    BCardBody,
    BCard,
    BTable,
    BBadge,
    BButton,
  },
  data() {
    return {
      fields: [
        {
          key: 'title',
          label: 'BİLDİRİM',
        },
        {
          key: 'push_date',
          label: 'GÖNDERİM TARİHİ',
          thClass: 'text-nowrap width-200',
          tdClass: 'text-nowrap width-200',
        },
        {
          key: 'is_pushed',
          label: 'DURUM',
          thClass: 'text-nowrap text-center width-200',
          tdClass: 'text-nowrap text-center width-200',
        },
        {
          key: 'control',
          label: '',
          thClass: 'text-nowrap width-50',
        },
      ],
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['pushNotifications/dataList']
    },
    dataCounts() {
      return this.$store.getters['pushNotifications/dataCounts']
    },
    saveData() {
      return this.$store.getters['pushNotifications/dataSaveStatus']
    },
  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
      this.getDataList()
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$store.dispatch('pushNotifications/getDataList', {
        where: {
          'push_notifications.id_offers': this.$route.params.id,
        },
      })
    },
    removeData(id) {
      this.$swal({
        title: this.$store.state.app.removeTitle,
        text: this.$store.state.app.removeMessage,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('pushNotifications/removeData', id)
          this.$swal({
            icon: 'success',
            title: this.$store.state.app.removeResultTitle,
            text: this.$store.state.app.removeResultMessage,
            confirmButtonText: this.$store.state.app.removeResultClose,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
