<template>
  <div>
    <b-tabs
      pills
      fill
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="FileTextIcon" />
          <span>Katalog Bilgileri</span>
        </template>
        <validation-observer ref="simpleRules">
          <catalog-view />
        </validation-observer>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="BellIcon" />
          <span>Uygulama Bildirimi</span>
        </template>
        <push-notifications />
      </b-tab>
      <b-tab
        :disabled="dataItem.id_catalog_statuses === '1'"
        lazy
      >
        <template #title>
          <feather-icon icon="EyeIcon" />
          <span>Müşteri İzleme</span>
        </template>
        <tracings />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BTabs, BTab,
} from 'bootstrap-vue'
import { ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import CatalogView from '@/views/Admin/Catalogs/CatalogView.vue'
import PushNotifications from '@/views/Admin/Catalogs/PushNotifications.vue'
import Tracings from '@/views/Admin/Catalogs/elements/CatalogView/Tracings.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'Add',
  components: {
    BTabs,
    BTab,
    CatalogView,
    PushNotifications,
    Tracings,
    ValidationObserver,
  },
  data() {
    return {
      required,
      submitStatus: false,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['catalogs/dataItem']
    },
  },
  created() {
    this.getData()
    localize('tr')
  },
  methods: {
    getData() {
      this.$store.dispatch('catalogs/getDataItem', this.$route.params.id)
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
