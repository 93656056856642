<template>
  <b-row>
    <b-col
      v-for="item in dataList"
      :key="item.id"
      cols="12"
      md="3"
    >
      <div class="border p-1 rounded text-center mb-3">
        <div class="overflow-hidden rounded">
          <b-img
            :src="baseURL + '/media/products/' + item.image"
            fluid-grow
          />
        </div>
        <div class="text-primary font-weight-bold mt-1">
          {{ item.product }}
        </div>
        <div class="text-secondary">
          {{ item.title }}
        </div>
        <div class="w-100 d-flex justify-content-between border-top pt-1 mt-1">
          <div :class="item.likes > 0? 'text-danger' : 'text-muted'">
            <FeatherIcon
              icon="HeartIcon"
              size="18"
            /> {{ item.likes }}
          </div>
          <div class="text-success">
            <FeatherIcon
              icon="EyeIcon"
              size="18"
            /> {{ item.watch_counts | toNumber }}
          </div>
        </div>
        <div class="font-small-2 text-muted border-top mt-1 pt-1">
          <div>Son Görüntüleme Tarihi</div>
          <div>
            {{ moment(item.last_watch).format('llll') }}
          </div>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BImg } from 'bootstrap-vue'

export default {
  name: 'LikeCard',
  components: {
    BRow, BCol, BImg,
  },
  props: {
    dataList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      baseURL: this.$store.state.app.baseURL,
    }
  },
}
</script>
