<template>
  <b-card title="Müşteriler">
    <b-row>
      <b-col>
        <v-select
          v-model="filterParams.id_customers"
          :options="dataList"
          label="company"
          :reduce="item => item.id"
          placeholder="Seçiniz"
        />
      </b-col>
      <b-col cols="auto">
        <b-button
          variant="primary"
          @click="getDataList"
        >
          <FeatherIcon icon="FilterIcon" /> Filtrele
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'FiltersCard',
  components: {
    vSelect,
    BCard,
    BRow,
    BCol,
    BButton,
  },
  props: {
    dataList: {
      type: Array,
      required: true,
    },
    getDataList: {
      type: Function,
      required: true,
    },
  },
  computed: {
    filterParams() {
      return this.$store.getters['catalogTracings/filterParams']
    },
  },
}
</script>
