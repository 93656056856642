<template>
  <b-row
    v-if="dataItem.items.length > 0"
  >
    <b-col
      v-for="(item, index) in dataItem.items"
      :key="index"
      cols="12"
      md="3"
    >
      <div
        class="border-light rounded p-1 mb-2 bg-light"
      >
        <div
          class="height-auto overflow-hidden"
        >
          <swiper
            v-if="item.images.length"
            class="swiper-navigations"
            :options="swiperOptions"
          >
            <swiper-slide
              v-for="(img,index) in item.images"
              :key="index"
            >
              <div class="w-100 h-auto rounded text-center bg-white">
                <b-img
                  :src="baseURL + '/media/products/' + img.thumb"
                  fluid
                  class="w-100"
                />
                <div class="font-small-3 py-1 text-primary">
                  {{ img.title }}
                </div>
              </div>
            </swiper-slide>
            <div
              slot="pagination"
              class="swiper-pagination"
            />
          </swiper>
          <empty-image
            v-else
            class="mx-auto"
          />
          <div class="text-center pt-1 border-top mt-3">
            <div class="font-weight-bolder text-primary text-nowrap">
              {{ item.pcode }}
            </div>
            <div class="text-secondary text-nowrap">
              {{ item.title }}
            </div>
            <div class="font-small-2 text-muted">
              {{ item.product_group }}
            </div>
          </div>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import {
  BImg, BRow, BCol,
} from 'bootstrap-vue'
import EmptyImage from '@/layouts/components/common/EmptyImage.vue'

export default {
  name: 'Products',
  components: {
    BImg,
    BRow,
    BCol,
    EmptyImage,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      baseURL: this.$store.state.app.baseURL,
      swiperOptions: {
        effect: 'cube',
        grabCursor: true,
        cubeEffect: {
          shadow: false,
          slideShadows: true,
          shadowOffset: 20,
          shadowScale: 0.94,
        },
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['catalogs/dataItem']
    },
  },
}
</script>
<style>
.swiper-pagination{
    bottom: -30px !important;
}
.swiper-pagination-bullet-active{
  background: #666666 !important;
}
</style>
